/**
 * Created by slava on 20/3/20
 */

var UndoBlock = cc.Node.extend({
    ctor: function (movesLog) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.log = movesLog;
        this.booster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_UNDO);

        this.createContent();
        this.onChangeAmount();
        this.setCascadeOpacityEnabledRecursively(true);

        this.state = UndoBlock.NONE;
        this.updateState(true);

        this.booster.on("changeAmount", this.createListener(this.onChangeAmount.bind(this)), this);
        cleverapps.adsLimits.on("update", this.onChangeAmount.bind(this), this);
        if (cleverapps.config.soft) {
            cleverapps.user.on("changeSoft", this.onChangeAmount.bind(this), this);
        } else {
            cleverapps.user.on("changeHard", this.onChangeAmount.bind(this), this);
        }
        this.log.on("change", this.updateState.bind(this));
        this.log.on("showForce", this.showForce.bind(this));

        this.log.on("showAlert", this.undoButton.showAlert.bind(this.undoButton));
    },

    createContent: function () {
        this.createPrice();
        this.undoButton = new UndoButton({
            onPressed: this.onClicked.bind(this)
        });

        var content = new cleverapps.Layout([this.undoButton, this.priceBlock].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL
        });
        if (cleverapps.config.type !== "klondike") {
            content.setAnchorPoint(0.5, 0);
        }
        this.setContentSize2(content.getContentSize());
        content.setPositionRound(content.width / 2, content.height / 2);
        this.addChild(content);

        this.updateSize();
    },

    updateSize: function () {
        if (this.priceBlock && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.priceBlock.setScale(cleverapps.styles.UndoBlock.priceBlock.mobileScale);
        } else {
            this.priceBlock.setScale(1);
        }
    },

    createPrice: function () {
        var styles = cleverapps.styles.UndoBlock;

        var price = this.price = new TextWithIcon("$$" + this.booster.getPrice().amount, { font: cleverapps.styles.FONTS.TEXT });

        var amount = this.amount = cleverapps.UI.generateImageText("x" + this.booster.getAmount(), cleverapps.styles.FONTS.AMOUNT_BOOSTER);

        var ads = this.ads = new TextWithIcon("## x1", {
            font: cleverapps.styles.FONTS.BUTTON_BOOSTER
        });

        this.priceBlock = new cc.Scale9Sprite(bundles.timer.frames.timer_bg_png);
        this.priceBlock.setContentSize2(price.width + 2 * styles.priceBlock.bg.padding.x, price.height + 2 * styles.priceBlock.bg.padding.y);
        this.priceBlock.addChild(price);
        this.priceBlock.addChild(amount);
        this.priceBlock.addChild(ads);

        amount.setPositionRound(this.priceBlock.width / 2, this.priceBlock.height / 2);
        price.setPositionRound(this.priceBlock.width / 2, this.priceBlock.height / 2);
        ads.setPositionRound(this.priceBlock.width / 2, this.priceBlock.height / 2);
    },

    onClicked: function () {
        var isRunningForce = cleverapps.forces.isRunningForce(this.booster.force);
        if (isRunningForce) {
            cleverapps.forces.closeRunningForce();
            this.priceBlock && this.priceBlock.setVisible(true);
            this.updateState();
            this.log.executeUndo();
        }

        cleverapps.focusManager.display({
            focus: "ExecuteUndoBooster",
            action: function (f) {
                this.booster.onPressed(f, isRunningForce);
            }.bind(this)
        });
    },

    updateState: function (silent) {
        if (this.log.isEmpty()) {
            if (this.state !== UndoBlock.UNAVAILABLE) {
                this.state = UndoBlock.UNAVAILABLE;
                this.fadeOut(silent);
            }
        } else if (this.state !== UndoBlock.AVAILABLE) {
            this.state = UndoBlock.AVAILABLE;
            this.fadeIn(silent);
        }
    },

    fadeIn: function (silent) {
        if (silent) {
            this.undoButton.enable();
            this.priceBlock.setOpacity(255);
            return;
        }

        this.undoButton.enable();
        this.undoButton.setVisible(true);
        this.undoButton.background.setAnimation(0, "fadein", false);
        this.priceBlock.stopAllActions();
        this.priceBlock.runAction(new cc.Sequence(
            new cc.Show(),
            new cc.FadeIn(0.5)
        ));
    },

    fadeOut: function (silent) {
        if (silent) {
            this.priceBlock.setOpacity(0);
            this.undoButton.setVisible(false);
            return;
        }
        this.undoButton.disable();
        this.undoButton.background.setAnimation(0, "fadeout", false);
        this.priceBlock.stopAllActions();
        this.priceBlock.runAction(new cc.Sequence(
            new cc.FadeOut(0.6),
            new cc.Hide(),
            new cc.CallFunc(function () {
                this.undoButton.setVisible(false);
            }.bind(this))
        ));
    },

    onChangeAmount: function () {
        var boosterState = this.booster.getState();
        this.setAmount(this.booster.getAmount());
        this.amount.setVisible(boosterState === BaseBooster.AMOUNT_STATE);
        this.price.setVisible(boosterState === BaseBooster.PRICE_STATE);
        this.ads.setVisible(boosterState === BaseBooster.ADS_STATE);
    },

    setAmount: function (amount) {
        this.amount.setString("x" + amount);
    },

    showForce: function () {
        this.priceBlock && this.priceBlock.setVisible(false);

        var force = cleverapps.clone(this.booster.force);
        force.finger = false;
        force.pointer = true;
        cleverapps.forces.create(this.undoButton, force);
    }
});

UndoBlock.NONE = 0;
UndoBlock.AVAILABLE = 1;
UndoBlock.UNAVAILABLE = 2;

cleverapps.styles.UndoBlock = {
    priceBlock: {
        mobileScale: 0.7,

        bg: {
            padding: {
                x: 5,
                y: 1
            }
        }
    }
};