/**
 * Created by mac on 4/24/20
 */

CardView.prototype.ctor = cleverapps.extendFunc(CardView.prototype.ctor, function (card) {
    this._super.apply(this, arguments);

    if (cleverapps.environment.isEditorScene()) {
        this.createFrame();
    }

    if (cleverapps.environment.isEditorScene() && !card.random) {
        this.createPoint();
    }

    card.on("addMark", this.addMark.bind(this));
    card.on("removeMark", this.removeMark.bind(this));
    card.on("editorRemove", this.removeFromParent.bind(this));
    card.on("editorColor", this.showSelected.bind(this));
    card.on("setActive", this.setActive.bind(this));

    if (cleverapps.environment.isEditorScene() && !card.disableEditorControls) {
        this.addCardControls();
        this.addCardClickHandler();
    }
});

CardView.prototype.createPoint = function () {
    var node = new cc.Sprite(bundles.editor.frames.editor_attetion_png);
    node.setScale(0.75);
    this.addChild(node);
    node.setPositionRound(cleverapps.styles.CardViewControls.point);
};

CardView.prototype.addMark = function (type) {
    var markView = new MarkView(new Mark(type));
    this.addChild(markView);
    markView.show();
};

CardView.prototype.removeMark = function (type) {
    this.getChildren().forEach(function (child) {
        if (child instanceof MarkView && child.mark && child.mark.type === type) {
            child.removeFromParent();
        }
    });
};

CardView.prototype.createFrame = function () {
    var image = cleverapps.config.type === "tile3" ? bundles.editor.frames.tile_frame : bundles.editor.frames.card_frame;
    var frame = this.frame = new cc.Sprite(image);
    frame.setLocalZOrder(1);
    frame.setAnchorPoint(this.animation.getAnchorPoint());
    frame.setPosition(this.animation.getPosition());
    this.addChild(frame);
    frame.setVisible(false);
};

CardView.prototype.toggleFrameVisibility = function (visible) {
    this.animation.setVisible(!visible);
    if (cleverapps.config.type === "tile3") {
        this.icon.setVisible(!visible);
    }
    this.frame.setVisible(visible);
};

CardView.prototype.changeChildrenVisibility = function (visible) {
    this.children.forEach(function (child) {
        if (this.cardControls.indexOf(child) === -1) {
            child.visible = visible;
        }
    }.bind(this));
};

CardView.prototype.setActive = function (isActive) {
    if (isActive && !this.transparentImage || !isActive && this.transparentImage) {
        return;
    }

    if (isActive) {
        this.changeChildrenVisibility(true);

        this.transparentImage.removeFromParent();
        this.transparentImage = undefined;

        this.addCardClickHandler();
    } else {
        this.changeChildrenVisibility(false);

        this.transparentImage = new cc.Sprite(bundles.editor.frames.transparent_card);
        this.transparentImage.setOpacity(150);
        this.transparentImage.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.transparentImage);

        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }
    }
};

CardView.prototype.showSelected = function (selected) {
    this.setRecCascadeColorEnabled();

    var color = cleverapps.styles.COLORS.WHITE;
    if (selected) {
        color = cleverapps.styles.COLORS.YELLOW;
    } else if (cleverapps.config.type === "tile3" && !this.card.isOpen()) {
        color = cleverapps.styles.COLORS.DARK_TILE_COLOR;
    }
    this.setColor(color);

    if (!Game.currentGame.table.selectBlocksMode && !Game.currentGame.table.snapMode) {
        this.cardControls.forEach(function (control) {
            control.setVisible(selected);
        });
    }
};

CardView.prototype.listCardControls = function () {
    var cardControls = [];

    if (cleverapps.config.type === "solitaire") {
        cardControls = cardControls.concat(this.getRotationControls());
    }
    cardControls = cardControls.concat(this.getZOrderControls());
    cardControls.push(this.getDeleteControl());

    return cardControls;
};

CardView.prototype.getRotationControls = function () {
    var styles = cleverapps.styles.CardViewControls.rotate;
    var controls = [];

    styles.forEach(function (rotateControl) {
        var rotateIcon = new cc.Sprite(bundles.editor.frames.editor_rotate_arrow);
        rotateIcon.setRotation(rotateControl.rotate);
        rotateIcon.setPositionRound(rotateControl);
        controls.push(rotateIcon);

        var calcAngle = function (touch) {
            var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
            var x = (rotateIcon.x + displacement.x) - this.width / 2;
            var y = (rotateIcon.y + displacement.y) - this.height / 2;
            return Math.atan2(x, y) / Math.PI * 180 - rotateControl.baseAngle;
        }.bind(this);

        cleverapps.UI.onDrag(rotateIcon, {
            onDragMove: function (touch) {
                Game.currentGame.table.editorRotateCardView(calcAngle(touch));
            },
            onDragEnd: function (touch) {
                Game.currentGame.table.editorRotateCard(calcAngle(touch));
            }
        });
    }.bind(this));
    return controls;
};

CardView.prototype.getDeleteControl = function () {
    var styles = cleverapps.styles.CardViewControls.delete;
    var deleteIcon = new cc.Sprite(bundles.editor.frames.editor_delete_icon);
    deleteIcon.setPositionRound(styles);
    cleverapps.UI.onClick(deleteIcon, function () {
        Game.currentGame.table.removeCardsEditor();
    });
    return deleteIcon;
};

CardView.prototype.getZOrderControls = function () {
    var styles = cleverapps.styles.CardViewControls;
    var controls = [];
    var orderDownIcon = new cc.Sprite(bundles.editor.frames.editor_orderdown_icon);
    orderDownIcon.setPositionRound(styles.orderdown);
    controls.push(orderDownIcon);
    cleverapps.UI.onClick(orderDownIcon, function () {
        Game.currentGame.table.changeOrder(false);
        orderDownIcon.runAction(StandartAnimations.shake(orderDownIcon));
    });
    cleverapps.UI.applyHover(orderDownIcon);

    var orderUpIcon = new cc.Sprite(bundles.editor.frames.editor_orderup_icon);
    orderUpIcon.setPositionRound(styles.orderup);
    controls.push(orderUpIcon);
    cleverapps.UI.onClick(orderUpIcon, function () {
        Game.currentGame.table.changeOrder(true);
        orderUpIcon.runAction(StandartAnimations.shake(orderUpIcon));
    });
    cleverapps.UI.applyHover(orderUpIcon);

    return controls;
};

CardView.prototype.canSetCard = function () {
    return true;
};

CardView.prototype.addCardControls = function () {
    this.cardControls = this.listCardControls();

    this.cardControls.forEach(function (control) {
        control.setVisible(false);
        control.setLocalZOrder(20);
        this.addChild(control);
    }.bind(this));
};

CardView.prototype.addCardClickHandler = function () {
    var move = function (pos, isDragEnd) {
        var processor = "editorCardMove";
        if (isDragEnd) {
            processor = "editorCardMoveEnd";
        }
        Game.currentGame.table[processor](pos);
    };

    var orangery = Editor.currentEditor.orangery;

    var selected = true;
    var hasMoved = false;
    var orangeryComponentSelected = undefined;
    var orangeryCardTypeSelected = undefined;
    var lastClick = 0;

    this.clickHandler = cleverapps.UI.onDrag(this, {
        onClick: function (touch) {
            if (orangery.selected && !orangery.selected.component) {
                var point = this.convertTouchToNodeSpace(touch);

                var clickInCenter = cc.rectContainsPoint(
                    cc.rectScale(cc.rect(0, 0, this.width, this.height), 0.5),
                    point
                );
                if (!clickInCenter) {
                    var tableView = Game.currentGame.table.getView().getParent();

                    var tablePos = tableView.convertTouchToNodeSpace(touch);
                    Game.currentGame.table.editorClicked({
                        x: tablePos.x,
                        y: tablePos.y
                    });
                    return false;
                }
            }

            hasMoved = false;
            orangeryComponentSelected = orangery && orangery.selected && orangery.selected.component;
            orangeryCardTypeSelected = orangery && orangery.selected && orangery.selected.cardType;
            selected = Game.currentGame.table.selected.has(this.card);
            var isPressedCtrl = cleverapps.keyboardController.isPressed(cc.KEY.ctrl);

            if (orangeryComponentSelected) {
                Game.currentGame.table.putComponent(this.card, orangery.selected);
            } else if (orangeryCardTypeSelected) {
                var newCard = {
                    x: this.card.x,
                    y: this.card.y,
                    CardCtor: orangery.selected.CardCtor,
                    cardOptions: orangery.selected.cardOptions
                };
                if (!this.card.magic) {
                    newCard.value = this.card.value;
                } else {
                    newCard.random = true;
                }
                Game.currentGame.table.addNewCard(newCard);
                Game.currentGame.table.removeCard(this.card);
            } else if (selected) {
                if (isPressedCtrl) {
                    Game.currentGame.table.unselect(this.card);
                }
            } else {
                if (!isPressedCtrl) {
                    Game.currentGame.table.clearSelection();
                }
                Game.currentGame.table.select(this.card);
            }
        }.bind(this),

        onDragStart: function () {
            return true;
        },
        onDragMove: function (touch) {
            var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
            if (Math.abs(displacement.x) >= 2 || Math.abs(displacement.y) >= 2) {
                move({
                    x: displacement.x,
                    y: displacement.y,
                    r: 0
                }, false);
                hasMoved = true;
            }
        }.bind(this),
        onDragEnd: function (touch) {
            var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
            if (!hasMoved && Math.abs(displacement.x) < 2 && Math.abs(displacement.y) < 2) {
                var now = Date.now();
                if (now - lastClick < PointerHandler.DOUBLE_CLICK_WAIT) {
                    Game.currentGame.table.cardHeld(this.card);
                } else {
                    lastClick = now;
                    if (selected) {
                        if (cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                            Game.currentGame.table.unselect(this.card);
                        } else {
                            Game.currentGame.table.clearSelection();
                            Game.currentGame.table.select(this.card);
                        }
                    }
                }
            } else {
                if (!this.canSetCard()) {
                    displacement.x = 0;
                    displacement.y = 0;
                }

                move({
                    x: displacement.x,
                    y: displacement.y,
                    r: 0
                }, true);
            }
        }.bind(this)
    });
};

cleverapps.styles.CardViewControls = {
    rotate: [
        {
            x: { align: "right", dx: 10 },
            y: { align: "top", dy: 12 },
            rotate: -90,
            baseAngle: 32
        },
        {
            x: { align: "right", dx: 10 },
            y: { align: "bottom", dy: -10 },
            rotate: 0,
            baseAngle: 147
        },
        {
            x: { align: "left", dx: -10 },
            y: { align: "bottom", dy: -10 },
            rotate: 90,
            baseAngle: -147
        },
        {
            x: { align: "left", dx: -10 },
            y: { align: "top", dy: 12 },
            rotate: 180,
            baseAngle: -32
        }
    ],

    delete: {
        x: { align: "center" },
        y: { align: "bottom", dy: -20 }
    },

    orderdown: {
        x: { align: "left", dx: -8 },
        y: { align: "center" }
    },

    orderup: {
        x: { align: "right", dx: 8 },
        y: { align: "center" }
    },

    point: {
        x: { align: "left" },
        y: { align: "bottom" }
    }
};
