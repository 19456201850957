/**
 * Created by slava on 20/3/20
 */

MovesLog.MOVE_SHIFT_CARD = 1;
MovesLog.MOVE_USE_BOOSTER = 2;

MovesLog.prototype.showUndoForce = function () {
    cleverapps.focusManager.display({
        focus: "UndoForce",
        control: ["opencards", "undoBlock"],
        actions: [
            function (f) {
                setTimeout(f, 500);
            },

            function (f) {
                this.trigger("showForce");
                cleverapps.forces.onceForceClosed = f;
            }.bind(this),

            function (f) {
                setTimeout(f, 100);
            },

            function (f) {
                var missed = this.tilegame.table.listMissed();
                if (missed.length) {
                    this.tutorialCard = missed[0];
                    this.tutorialCard.trigger("showTutorial");
                }
                f();
            }.bind(this)
        ]
    });
};

MovesLog.prototype.executeUndo = function () {
    var move = this.log.pop();

    switch (move.type) {
        case MovesLog.MOVE_SHIFT_CARD:
            var card = this.tilegame.open.pop();
            this.tilegame.unShiftCard(card);
            break;

        case MovesLog.MOVE_PLAY_CARD:
            this.undoPlayCard();
            break;

        case MovesLog.MOVE_USE_BOOSTER:
            var booster = cleverapps.boosters.getBoosterById(move.options.id);
            booster.makeUndo();
            break;
    }

    if (move.type !== MovesLog.MOVE_USE_BOOSTER) {
        if (this.tilegame.combo) {
            if (move.options && move.options.comboLevel) {
                while (this.tilegame.combo.level < move.options.comboLevel) {
                    this.tilegame.combo.increaseLevel();
                }
            } else {
                if (move.options && move.options.resetAfterPrize !== undefined) {
                    this.tilegame.combo.resetAfterPrize = move.options.resetAfterPrize;
                }
                this.tilegame.combo.decreaseLevel();
            }
        }

        if (this.tilegame.table.streakCard) {
            this.tilegame.table.streakCard.decreaseLevel();
        }
    }

    this.trigger("change");
    this.tilegame.move = Card.MOVES.UNDO;
    this.tilegame.counter.trigger();
};

MovesLog.prototype.isUndoNeedOnPreviousMove = function () {
    var lastMove = this.getLastMove();

    return lastMove && lastMove.options && lastMove.options.undoRecommended;
};

MovesLog.prototype.isUndoForceAvailable = function () {
    if (!Game.currentGame || Game.currentGame.cardsTutorial || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN
        || !this.undoBooster.isForceAvailable()) {
        return false;
    }

    return this.isUndoNeedOnPreviousMove();
};

MovesLog.prototype._showTutorialStep = MovesLog.prototype.showTutorialStep;
MovesLog.prototype.showTutorialStep = function () {
    if (this.isUndoForceAvailable()) {
        this.showUndoForce();
        return true;
    }

    this._showTutorialStep();
};