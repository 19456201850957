/**
 * Created by slava on 19/3/20
 */

var WildCardButton = BaseButtonBooster.extend({
    ctor: function (booster) {
        this._super({
            booster: booster,
            styles: cleverapps.styles.WildCardButtonBooster,
            spine: bundles.game.jsons.joker_button_json
        });

        booster.onCardCreatedListener = this.createListener(this.createCardView.bind(this));
        booster.onShowTutorial = this.createListener(this.showTutorial.bind(this));

        this.updateSize();
        this.setupChildren();
        this.setLocalZOrder(10);

        if (this.hoverHandler) {
            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }

        cleverapps.UI.applyHover(this.background);
    },

    createFreePrice: function () {
        var freeText = cleverapps.UI.generateTTFText("FREE", cleverapps.styles.FONTS.BUTTON_BOOSTER);
        freeText.fitTo(this.priceBlock.width);
        this.priceBlock.addChild(freeText);
        freeText.setPositionRound(this.priceBlock.width / 2, this.priceBlock.height / 2);
        return freeText;
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.WildCardButtonBooster.position);
    },

    updateSize: function () {
        this.baseScale = 1;
        this.setScale(this.baseScale);
    },

    toggleArrow: function () {
    },

    toggleTint: function (on) {
        if (on) {
            this.showAlert();
        }
    },

    getContent: function () {
        var styles = cleverapps.styles.WildCardButtonBooster.price;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);

        var price = this.price = this.priceText = new TextWithIcon("$$" + this.booster.getPrice().amount, {
            font: cleverapps.styles.FONTS.TEXT
        });

        var priceBlock = this.priceBlock = new cc.Scale9Sprite(bundles.timer.frames.timer_bg_png);
        priceBlock.setContentSize2(price.width + 2 * styles.bg.padding.x, price.height + 2 * styles.bg.padding.y);
        content.addChild(priceBlock);
        priceBlock.addChild(price);
        price.setPositionRound(priceBlock.width / 2, priceBlock.height / 2);
        priceBlock.setPositionRound(styles);

        var amount = this.amount = this.amountText = cleverapps.UI.generateImageText("x" + this.booster.getAmount(), cleverapps.styles.FONTS.AMOUNT_BOOSTER);
        amount.setPositionRound(priceBlock.width / 2, priceBlock.height / 2);
        priceBlock.addChild(amount);

        var ads = this.ads = this.amountText = new TextWithIcon("## x1", {
            font: cleverapps.styles.FONTS.BUTTON_BOOSTER
        });
        ads.setPositionRound(priceBlock.width / 2, priceBlock.height / 2);
        priceBlock.addChild(ads);
        return content;
    },

    createCardView: function (card) {
        var ViewClass = card.getViewClass();
        var view = new ViewClass(card);
        view.setPosition(this.width / 2, this.height / 2);
        this.addChild(view);
    },

    showTutorial: function () {
        this.switchToFreePrice();
        return true;
    },

    switchToRegularPrice: function () {
        if (!this.booster.wildcardTutorial) {
            this._super.call(this);
        }
    }
});

cleverapps.styles.WildCardButtonBooster = {
    width: 116,
    height: 165,

    price: {
        x: { align: "center" },
        y: { align: "bottom", dy: -103 },

        bg: {
            padding: {
                x: 5,
                y: 1
            }
        }
    },

    position: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: 21 }
    },

    content: {
        price: {
            x: { align: "center" },
            y: { align: "bottom", dy: -50 },
            scale: 0.7
        }
    }
};