/**
 * Created by Aleksandr on 10.03.2023
 */

var MarkView = cleverapps.Spine.extend({
    ctor: function (mark) {
        this.mark = mark;
        this.styles = cleverapps.styles.MarkView[this.mark.type];

        if (cleverapps.environment.isSceneWithPreview()) {
            this.styles = cleverapps.overrideStyles(this.styles, this.styles.editor || {}, true);
        }

        var json = cleverapps.skins.getSlot("cardMarkSpine", {
            json: bundles.card.jsons.marks_json,
            type: mark.type
        });

        this._super(json);
        this.setAnchorPoint(0.5, 0.5);

        var animation;
        switch (this.mark.type) {
            case "mission": animation = "gem_idle"; break;
            case "key": animation = "key_idle"; break;
            case "coin": animation = "coin_idle"; break;
            case "magnet": animation = "magnet_idle_0"; break;
            case "bone": animation = "bone_idle"; break;
        }

        this.setAnimation(0, animation, true);
        this.setTimeScale(0.7 + Math.random() * 0.6);
        this.setScale(0);

        this.mark.on("show", this.show.bind(this));
        this.mark.on("collect", this.collect.bind(this));

        if (!cleverapps.environment.isSceneWithPreview()) {
            this.mark.on("hide", this.hide.bind(this));
        }
    },

    show: function (silent, callback) {
        this.setVisible(true);
        this.setPositionRound(this.styles);

        var scale = this.styles.scale || 1;

        if (silent) {
            this.setScale(scale);
            return;
        }

        var spark = new cleverapps.Spine(bundles.card.jsons.effects_json);
        spark.setAnimation(0, "appearance_corner", false);
        spark.setPositionRound(this.x, this.y);
        this.parent.addChild(spark);

        this.setScale(0);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.33),
            new cc.ScaleTo(0.33, scale),
            new cc.CallFunc(function () {
                spark.removeFromParent();
                if (callback) {
                    callback();
                }
            })
        ));
    },

    hide: function () {
        this.setVisible(false);
    },

    collect: function (target) {
        if (!target) {
            this.removeFromParent();
            return;
        }

        this.setTimeScale(1);

        var sound;
        switch (this.mark.type) {
            case "mission": sound = bundles.game.urls.cell_collect_effect; break;
            case "key": sound = bundles.game.urls.key_effect; break;
        }

        this.runAction(new cc.Sequence(
            new cc.Spawn(
                StandartAnimations.animateCollect(this, target, {
                    duration: Mark.COLLECT_DURATION / 1000,
                    sound: sound,
                    collectEffect: true
                }),
                new cc.RotateTo(Mark.COLLECT_DURATION / 1000, 0)
            ),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.MarkView = {
    "mission": {
        x: 15,
        y: 15
    },

    "key": {
        x: 95,
        y: 50,

        editor: {
            x: { align: "center" },
            y: { align: "center", dy: 60 },
            scale: 2
        }
    },

    "coin": {
        x: 15,
        y: 15
    },

    "magnet": {
        x: 95,
        y: 20,

        offset: {
            x: 0,
            y: 150
        },

        lightningLength: 530,

        editor: {
            x: { align: "center" },
            y: { align: "center", dy: -20 },
            scale: 1.5
        }
    },

    "bone": {
        x: 90,
        y: 80
    }
};
