/**
 * Created by Andrey Popov on 04.09.20
 */

var JackpotCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.jackpot_json
        });

        card.on("win", this.win.bind(this));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.6),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.jackpot_add_effect);
            })
        ));
    },

    onFlip: function () {
    },

    win: function (reward) {
        this.animation.setAnimation(0, "win", false);

        this.runAction(new cc.Sequence(
            new cc.Spawn(
                cleverapps.UI.animateCard({
                    cardView: this,
                    position: {
                        parent: this.parent,
                        point: cc.p(0, 0)
                    },
                    duration: JackpotCardView.GET_UP_TIME,
                    replaceParentBack: false
                }),
                new cc.RotateTo(JackpotCardView.GET_UP_TIME, 0)
            ),
            new cc.DelayTime(JackpotCardView.SPARK_TIME),
            StandartAnimations.coinCloud(this, {
                reward: reward,
                cloudSize: CoinCloud.BIG
            }),
            new cc.DelayTime(2),
            new cc.RemoveSelf()
        ));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.game.urls.jackpot_win_effect);
            })
        ));
    }
});

JackpotCardView.SPARK_TIME = 0.8;
JackpotCardView.GET_UP_TIME = 0.3;