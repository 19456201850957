/**
 * Created by andrey on 28.06.2020
 */

var Mark = function (type) {
    cleverapps.EventEmitter.call(this);

    this.type = type;
};

Mark.prototype = Object.create(cleverapps.EventEmitter.prototype);
Mark.prototype.constructor = Mark;

Mark.prototype.show = function (silent) {
    this.trigger("show", silent);
};

Mark.prototype.hide = function () {
    this.trigger("hide");
};

Mark.prototype.alwaysVisible = function () {
    return this.type === "magnet";
};

Mark.prototype.execute = function () {
    if (this.type === "magnet") {
        this.trigger("executeMagnet", this.cardsToDestroy);
        Game.currentGame.counter.setTimeout(function () {
            this.cardsToDestroy.forEach(function (cardToDestroy) {
                if (Game.currentGame.table.hasCard(cardToDestroy)) {
                    for (var i = 0; i < cardToDestroy.components.length; i++) {
                        if (cardToDestroy.components[i].magnetHit && cardToDestroy.components[i].magnetHit()) {
                            return;
                        }
                    }

                    if (!cardToDestroy.isOpen()) {
                        cardToDestroy.flip();
                    }
                    cardToDestroy.magnetHit();
                }
            });
            this.cardsToDestroy = undefined;
        }.bind(this), 300);
    }
};

Mark.prototype.collect = function () {
    if (this.type === "magnet") {
        this.cardsToDestroy = Game.currentGame.table.getRandomOpenedCardsForAttack(3);

        Game.currentGame.counter.setTimeout(function () {
            Game.currentGame.executeMark(this);
        }.bind(this), Mark.COLLECT_DURATION);

        this.trigger("collectMagnet");
        return;
    }

    var target;
    var callback;

    if (this.type === "key") {
        var chainToReturn = ChainOnCard.Unlock();
        if (chainToReturn && chainToReturn.card) {
            target = chainToReturn.card.onGetView();
        }
    } else if (this.type === "mission") {
        target = "mission_reward" + Mission.TYPE_LETTER;
        callback = function () {
            Game.currentGame.addClover(Mission.TYPE_LETTER, 1);
        };
    } else if (this.type === "coin") {
        target = "rewardHard";
        callback = function () {
            Game.currentGame.addHardReward(Mark.COIN_REWARD);
        };
    }

    if (callback) {
        Game.currentGame.setTimeout(callback, Mark.COLLECT_DURATION);
    }

    this.trigger("collect", target);
};

Mark.COIN_REWARD = 1;

Mark.COLLECT_DURATION = 700;
